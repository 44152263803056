<template>
  <section id="hero">
    <v-sheet
      dark
      tile
    >
      <v-img
        :src="require('@/assets/Main/about/about (5).webp')"
        :height="$vuetify.breakpoint.xsOnly ? '100vh' : '100vh'"
        :gradient="gradient"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
        <v-container
          light
          fill-height
          fluid
          :px-12="$vuetify.breakpoint.mdAndUp"
        >
          <v-row
            align="center"
            justify="start"
          >
            <v-slide-x-reverse-transition appear>
              <v-col
                cols="12"
                md="7"
                ma="0"
              >
                <div>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <h1
                    :class="[
                      $vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline'
                    ]"
                    class="font-weight-bold"
                  >
                    About Us
                  </h1>

                  <v-divider class="mb-3" />

                  <div class="subheading mb-4">
                    <p>
                      <em>"Are you barking up the wrong tree?"</em>
                    </p>
                  </div>
                  <v-row>
                    <!-- <v-btn
                      href="https://blockhousekitchen.co.za/food-menus/classic-menu/"
                      color="thirdley"
                      class="ml-3"
                      target="_blank"
                      rel="noopener"
                      tile
                      outlined
                    >
                      Menu&nbsp;&nbsp;
                      <span style="font-size: 16px;">
                        <font-awesome-icon
                          right
                          :icon="['fas', 'book-open']"
                        />
                      </span>
                    </v-btn> -->
                    <v-btn
                      href="#details"
                      color="thirdley"
                      class="ml-3"
                      tile
                      outlined
                    >
                      Details&nbsp;
                      <v-icon>{{ svgArrow }}</v-icon>
                    </v-btn>
                  </v-row>
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>

          <v-row
            align="center"
            justify="end"
          >
            <v-slide-x-reverse-transition appear>
              <v-col
                cols="12"
                align="center"
                justify="center"
                md="3"
              >
                <div>
                  <h2 class="hidden-sm-and-down">
                    074 189 6354
                  </h2>

                  <v-divider class="mb-3" />
                  <v-btn
                    href="tel:+27-74-189-6354"
                    target="_blank"
                    rel="noopener"
                    color="thirdley"
                    class="ml-3"
                    tile
                    outlined
                  >
                    Call &nbsp;&nbsp;&nbsp;
                    <v-icon v-text="'mdi-phone'" />
                  </v-btn>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br><br><br><br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-container>
      </v-img>
    </v-sheet>
  </section>
</template>

<style scoped>
#scroll_more {
  position: absolute;
  bottom: 12%;
  left: 50%;
  width: 100%;
  height: 6px;
  cursor: pointer;
}
</style>

<script>
  import {
    mdiGlassWine,
    mdiCompass,
    mdiFruitGrapes,
    mdiFruitGrapesOutline,
    mdiBottleWine,
    mdiFileDownload,
    mdiArrowDownBold
  } from '@mdi/js'

  export default {
    name: 'HomeHero',
    components: {
    // Boasters: () => import('@/components/Boasters.vue')
    },
    props: {
      gradient: {
        type: String,
        default: 'to top, rgba(0,0,0,.18), rgba(0,0,0,.18)'
      }
    },
    data: () => ({
      svgWine: mdiGlassWine,
      svgCompass: mdiCompass,
      svgGrape: mdiFruitGrapes,
      svgGrapeOut: mdiFruitGrapesOutline,
      svgBottle: mdiBottleWine,
      svgDown: mdiFileDownload,
      svgArrow: mdiArrowDownBold,

      sbsocials: [
        ['https://www.facebook.com/SushiBoxSA', 'mdi-facebook', 'Facebook'],
        ['https://twitter.com/SushiBoxSA', 'mdi-twitter', 'Twitter'],
        ['https://www.instagram.com/sushiboxsa/', 'mdi-instagram', 'Instagram']
      ]
    })
  }
</script>
